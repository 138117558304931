// extracted by mini-css-extract-plugin
export var shippingPolicy = "ShippingPolicy-module--shippingPolicy--VmyrI";
export var content = "ShippingPolicy-module--content--164t_";
export var container = "ShippingPolicy-module--container--U5X8v";
export var title = "ShippingPolicy-module--title--ClMJN";
export var subtitle = "ShippingPolicy-module--subtitle--191rs";
export var description = "ShippingPolicy-module--description--3yH29";
export var textEmail = "ShippingPolicy-module--textEmail--uw0Sv";
export var buttonToTop = "ShippingPolicy-module--buttonToTop--2TVx5";
export var buttonToTopHidden = "ShippingPolicy-module--buttonToTopHidden--GW34r";
export var footerContainer = "ShippingPolicy-module--footerContainer--Pti79";