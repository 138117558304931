import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { graphql } from 'gatsby'
import { trackingGA } from '@merchx-v2/helpers'
import { Footer, Header, SEO } from "components";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as styles from "./ShippingPolicy.module.scss";

const ShippingPolicy = ({ menu, resetMenuFilters, setMenuCollection, data }) => {
  const [showButtonClass, setShowButtonClass] = useState("buttonToTopHidden");

  const onScroll = (e) => {
    if (window.pageYOffset > 100) {
      setShowButtonClass("buttonToTop");
    } else {
      setShowButtonClass("buttonToTopHidden");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.scrollTo(0, 0);
    trackingGA()

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className={styles.shippingPolicy}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname='/shipping-policy'
      />
      <div className={styles.content}>
        <Header
          menu={menu}
          onCollectionClicked={handleCollectionClicked}
          onResetCollection={handleReset}
        />
        <div className={styles.container}>
          <div className={styles.title}>Shipping Policy</div>
          <div className={styles.subtitle}>Standard Shipping</div>
          <div className={styles.description}>
            Due to the ongoing pandemic, some orders may face delays of up to
            1-2 weeks.
            <br />
            However, most orders will be delivered within 5-7 business days!
          </div>
          <div className={styles.description}>
            We will get your order out to you as fast as possible. Stay safe and
            strong.
          </div>
          <div className={styles.description}>
            To track your package please go to{" "}
            <a
              className={styles.textEmail}
              href="https://track.firstmile.com/"
              target="blank"
            >
              https://track.firstmile.com/
            </a>
          </div>
        </div>
        <button
          className={styles[showButtonClass]}
          onClick={(e) => window.scroll(0, 0)}
        >
          Back to top
        </button>
      </div>

      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
});
const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPolicy);

export const query = graphql`
  query ShippingPolicyPage {
    markdownRemark(fields: {slug: {eq: "/shipping-policy/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`
